<template>
  <!-- 서브페이지 내용 -->
  <div class="con-wrap">
    <CarrotTitle title="재물조사 등록">
      - 재물조사를 등록합니다.<br />
      - 입력한 재물조사기간에 맞추어 자동으로 재물조사 팝업이 노출됩니다.
    </CarrotTitle>
    <div class="width-50per">
      <div class="mt-40">
        <div>
          <div class="mb-20">
            <div class="clear"></div>
          </div>
          <table class="table-row">
            <colgroup>
              <col width="180" />
              <col width="*" />
            </colgroup>
            <tbody>
              <tr>
                <th>제목</th>
                <td>
                  <input type="text" class="w-100per" maxlength="50" v-model.trim="add.title" placeholder="OOOO년 재물조사" />
                </td>
              </tr>
              <tr>
                <th>재물조사기간</th>
                <td>
                  <v-datepicker v-model="add.sdate" class="datepicker-group">
                    <template #default="{ inputValue, togglePopover }">
                      <div>
                        <input type="text" :value="inputValue" class="input w-80px" @click="togglePopover()" />
                        <button class="btn-cal" @click="togglePopover()"></button>
                      </div>
                    </template>
                  </v-datepicker>
                  <v-datepicker v-model="add.edate" class="datepicker-group">
                    <template #default="{ inputValue, togglePopover }">
                      <div>
                        <input type="text" :value="inputValue" class="input w-80px" @click="togglePopover()" />
                        <button class="btn-cal" @click="togglePopover()"></button>
                      </div>
                    </template>
                  </v-datepicker>
                </td>
              </tr>
              <tr>
                <th>팝업내용</th>
                <td>
                  <textarea class="w-100per h-100px" v-model.trim="add.contents" maxlength="500"></textarea>
                </td>
              </tr>
              <tr>
                <th>안내내용</th>
                <td>
                  <textarea class="w-100per h-100px" v-model.trim="add.contents_info" maxlength="500"></textarea>
                </td>
              </tr>
              <tr>
                <th>팝업링크</th>
                <td>
                  <input type="text" class="w-100per" maxlength="50" v-model.trim="add.link" />
                </td>
              </tr>
            </tbody>
          </table>
          <button class="btn-default float-left mt-30" @click="add.goList">목록</button>
          <button class="btn-default float-right mt-30" @click="add.doSubmit">등록</button>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </div>
  <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive } from "vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import axios from "@/plugins/axios.js";
import Swal from "sweetalert2";
import { DatePicker } from "v-calendar";
import { useStore } from "vuex";

export default {
  layout: "myPIMS",
  components: {
    "v-datepicker": DatePicker,
  },
  setup() {
    const router = new useRouter();
    const toast = useToast();
    const store = useStore();

    const add = reactive({
      sdate: new Date(),
      edate: new Date(),
      title: "",
      contents: "아래 링크를 누르면 해당 페이지로 이동합니다.\n자신이 보유하고 있는 자산과 비교하여 신고하여 주시기 바랍니다.",
      contents_info: "",
      link: "myPIMS/myAssetList",

      doSubmit: () => {
        let params = {
          sdate: add.sdate,
          edate: add.edate,
          title: add.title,
          contents: add.contents,
          contents_info: add.contents_info,
          link: add.link,
        };

        if (params.sdate == "") {
          toast.error("모든 입력 항목을 작성하세요.");
          return;
        }
        if (params.edate == "") {
          toast.error("모든 입력 항목을 작성하세요.");
          return;
        }
        if (params.title == "") {
          toast.error("모든 입력 항목을 작성하세요.");
          return;
        }
        if (params.contents == "" || params.contents_info == "") {
          toast.error("모든 입력 항목을 작성하세요.");
          return;
        }
        if (params.link == "") {
          toast.error("모든 입력 항목을 작성하세요.");
          return;
        }

        axios.post("/rest/mypims/addAssetsPopup", params).then((res) => {
          if (res.data.err == 0) {
            toast.success("재물조사를 등록하였습니다.");
            router.push({
              name: "myPIMS-AssetManagementReportList",
            });
          } else {
            if (res.data.err_msg) toast.error(res.data.err_msg);
          }
        });
      },

      goList: () => {
        if (confirm("입력하신 내용이 사라집니다. 목록으로 이동하시겠습니까?") == true) {
          router.go(-1);
        }
      },
    });

    onMounted(() => {
      let auth = false;
      if (store.state.isSalesAdmin || store.state.isManageSupportAdmin)
        //재물조사등록은 총무+회계
        auth = true;

      if (auth == false) {
        router.go(-1);
        Swal.fire({
          title: "재물조사 등록",
          text: "접근 권한이 없습니다.",
        });
        return;
      }
      // add.doSearchOffice();
    });

    onUnmounted(() => {});

    return { add };
  },
};
</script>

<style lang="scss" scoped></style>
